import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface ICurrentFlat {
    ownership: string;
    projectName: string;
    flatNumber: string;
}

@Injectable({
    providedIn: 'root'
})
export class FlatDetailsLayoutService {

    /** 
     * Stores Project Name and Flat Number of current flat, fetched from router params. 
     * (To be set in flat-details layout)
    */
    currentFlat: BehaviorSubject<ICurrentFlat | null> = new BehaviorSubject<ICurrentFlat | null>(null);

    resetCurrentFlat() {
        this.currentFlat.next(null);
    }
}